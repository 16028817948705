import { makeStyles } from '@material-ui/core';
import React from 'react';
import Layout from '../components/layout';
import SEO from '../components/seo';
import content from '../content/imprint/page.json';
import FiraSans from '../fonts/Fira_Sans/firaSans';
import Colors from '../styles/colors';
import Dimensions, { narrowWidth } from '../styles/dimensions';

const Imprint = () => {
	const classes = useClasses();

	return (
		<Layout>
			<SEO title={content.title} />

			<div className={classes.page}>
				<div className={classes.container}>
					<h1 className={classes.header}>{content.title}</h1>
					{content.restOfSections.map((item, key) => (
						<section key={key}>
							<h3 className={classes.subheader}>{item.title}</h3>
							{item.paragraphParts ? (
								<p>
									{item.paragraphParts.map((part, index) => (
										<span
											key={index}
											style={
												part.isHighlighted
													? { color: Colors.limeGreen, fontWeight: 'bold' }
													: {}
											}>
											{part.text}
										</span>
									))}
								</p>
							) : (
								<p dangerouslySetInnerHTML={{ __html: item.paragraph }}></p>
							)}
						</section>
					))}
				</div>
			</div>
		</Layout>
	);
};

export default Imprint;

const useClasses = makeStyles({
	page: {
		backgroundColor: Colors.offerBackgroundBlack,
		color: Colors.whiteLightlyDimmed,
		fontFamily: FiraSans.regular,
		fontSize: Dimensions.regularText.fontSize,
		lineHeight: Dimensions.regularText.lineHeight,
		paddingTop: '3.5em',
		paddingBottom: '5.75em',
		paddingLeft: '0.8em',
		paddingRight: '0.8em',
		whiteSpace: 'pre-wrap',
		minHeight: 'calc(100vh - 9.2em)',
		boxSizing: 'border-box',
		'& a': {
			textDecoration: 'underline',
		},
	},
	container: {
		maxWidth: narrowWidth,
		margin: '0 auto',
	},
	header: {
		fontSize: Dimensions.largeTitle.fontSize,
		lineHeight: Dimensions.largeTitle.lineHeight,
		color: Colors.white,
		marginTop: 0,
		marginBottom: '0.5em',
		'@media (max-width: 599px)': {
			fontSize: Dimensions.regularTitle.fontSize,
			lineHeight: Dimensions.regularTitle.lineHeight,
		},
	},
	subheader: {
		color: Colors.white,
		marginTop: '1.25em',
		marginBottom: '0.625em',
		'@media (max-width: 599px)': {
			fontSize: Dimensions.largeText.fontSize,
			lineHeight: Dimensions.largeText.lineHeight,
		},
	},
	nameHeader: {
		marginTop: '0.8333333333333334em',
		marginBottom: '0.3333333333333333em',
		fontFamily: FiraSans.bold,
		'@media (max-width: 599px)': {
			fontSize: Dimensions.regularText.fontSize,
			lineHeight: Dimensions.regularText.lineHeight,
		},
	},
	adress: {
		margin: '0.4em 0',
	},
});
